import React, {useEffect} from "react"
import { useDispatch } from "react-redux"
import getFirebase from "../../utils/firebase"
import Footer from "../footer/footer"

import * as ACTIONS from "../../state/actions"

const date = new Date();

const footerContent = {
  es: {
    rights: `© ${date.getFullYear()} EVVA Plataforma S.A.P.I. de C.V. Todos los derechos reservados.`,
    crafted: "Diseñada con ♥ en Oslo & CDMX",
    description: "<p>EVVA Plataforma S.A.P.I. de C.V. (EVVA) se dedica exclusivamente al desarrollo de la plataforma tecnológica (La Plataforma) a través de la cual usted puede adquirir y administrar servicios financieros empresariales y  otorgando el uso de las misma a aquellas entidades financieras con las que se establezca relaciones comerciales o corporativas en términos de la legislación y normatividad aplicable. Por lo anterior, los fondos depositados a través de La Plataforma serán administrados por Kuspit Casa de Bolsa, S.A. de C.V., (Kuspit) entidad autorizada y supervisada por la Comisión Nacional Bancaria y de Valores, en virtud de un contrato de intermediación bursátil. Por lo anterior, EVVA es únicamente el intermediario tecnológico entre el usuario de La Plataforma y dichas instituciones financieras otorgando derecho al uso de la misma, en el entendido que (i) EVVA no ofrece servicios financieros, (ii) EVVA no presta actividades financieras o actividades que se encuentren reguladas o supervisadas por autoridad alguna. (iii) Kuspit es una entidad autorizada por el Gobierno Federal y, por tanto, la única responsable de las operaciones financieras que celebre con sus clientes, aun cuando ello suceda a través de la Plataforma.</p>",
    terms: "Términos de uso",
    privacy: "Política de privacidad"
  },
  en: {
    rights: `© ${date.getFullYear()} EVVA Plataforma S.A.P.I. de C.V. All rights reserved.`,
    crafted: "Crafted with ♥ in Oslo & Mexico City",
    description: "<p>EVVA is a digital platform through which you can manage business financial services. All financial services offered on the platform are carried out by one or more financial institutions, which are regulated and supervised by the CNBV and CONDUSEF. EVVA Plataforma, S.A.P.I. de C.V. It is only the technological intermediary between the user of the platform and said financial institutions, and therefore does not carry out financial activities or activities that are regulated or supervised by any authority.</p>",
    terms: "Terms of use",
    privacy: "Privacy Policy"
  }
};

const Layout = ({children, lang, footerDesc, privacyPolicyAction, termsUseHandlerAction}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getFirebase().analytics();
    dispatch(ACTIONS.setAmplitude(window.amplitude));
    dispatch(ACTIONS.setPath(window.location.pathname));
    dispatch(ACTIONS.setLang(window.location.pathname));
    dispatch(ACTIONS.setWindowWidth(window.innerWidth));
    window.addEventListener('resize', () => dispatch(ACTIONS.setWindowWidth(window.innerWidth)))
  },[dispatch]);

  return (
    <div>
      <main>{children}</main>
      <Footer content={footerContent[lang]} altDescription={footerDesc} privacyPolicyAction={privacyPolicyAction} termsUseHandlerAction={termsUseHandlerAction}/>
    </div>
  )
};

export default Layout
