import React from "react"
import styles from "./modal.module.scss"

const Modal = ({content, closeModal}) => (
  <div className={styles['modal']}>
    <div tabIndex={0} role="button" aria-label="closeBtn" className={styles['modal-bg']} onClick={() => closeModal()} onKeyUp={(e) => e.keyCode === 13 ? closeModal() : e.preventDefault()}/>
    <div className={styles['modal-window']}>
      <div tabIndex={0} role="button" className={styles['modal-window_closeBtn']} onClick={() => closeModal()} onKeyUp={(e) => e.keyCode === 13 ? closeModal() : e.preventDefault()}>×</div>
      <div className={styles['modal-window_content']}>{content}</div>
    </div>
  </div>
);

export default Modal