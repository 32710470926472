import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({title, description, page, lang}) => (
  <StaticQuery query={query} render={(data) => {
    const getDescription = description || data.site.siteMetadata.description;
    const getTitle = title || data.site.siteMetadata.title;
    const getImage = `https://evvafinanzas.com${data.site.siteMetadata.image}`;

    let canonical;
    let alternate;
    let alternateLang;

    if (lang === "es") {
        canonical = `https://evvafinanzas.com/${page || ''}`;
        alternate = `https://evvafinanzas.com/en/${page || ''}`;
        alternateLang = "en"
    } else {
        canonical = `https://evvafinanzas.com/${lang}/${page || ''}`;
        alternate = `https://evvafinanzas.com/${page || ''}`;
        alternateLang = "es"
    }

    return (
      <Helmet title={getTitle}>
        <html lang={lang}/>
        <meta name="description" content={getDescription} />
        <meta property="og:title" content={getTitle}/>
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonical}/>
        <meta property="og:image" content={getImage}/>
        <meta property="og:image:width" content="740"/>
        <meta property="og:image:height" content="740"/>
        <meta property="og:image:alt" content="EVVA Finanzas"/>
        <meta property="og:site_name" content="EVVA Finanzas"/>
        <meta property="og:description" content={getDescription}/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content={data.site.siteMetadata.author}/>
        <meta property="fb:app_id" content="892467844441319"/>
        <link rel="alternate" href={alternate} hrefLang={alternateLang} />
        <link rel="alternate" href={canonical} hrefLang={lang} />
        <link rel="canonical" href={canonical} hrefLang={lang} />
      </Helmet>
    )
  }} />
);

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        image
      }
    }
  }`;


export default SEO