import React from "react"
import styles from "./input.module.scss"

const Input = ({name, value, prompt, onChange, disabled}) => (
  <div className={[styles["input"], disabled ? styles["input-disabled"] : ""].join(" ")}>
    <input type="text" id={name} name={name} autoComplete="off" placeholder=" " value={value} onChange={e => onChange(e)} disabled={disabled}/>
    <label htmlFor={name}>{prompt}</label>
  </div>
);

export default Input