import React from "react"
import { isMobile } from "react-device-detect";
import Button from "../button/button"
import styles from "./optOut.module.scss"

const OptOut = ({text, privacyPolicyAction, privacyPolicyLabel, cookieAction}) => (
  <div className={styles["opt-out"]}>
    <div className={styles["opt-out_content"]}>
      <div className={styles["opt-out_content_text"]}>{text}
        {isMobile ? (
          <a href="../PC.pdf" target="_blank" rel="noopener noreferrer">{privacyPolicyLabel}</a>
        ) : (
          <span tabIndex={0} role="button" onClick={() => privacyPolicyAction()} onKeyUp={(e) => e.keyCode === 13 ? privacyPolicyAction() : e.preventDefault()}>{privacyPolicyLabel}</span>
        )}
      </div>
      <Button text="OK" onClick={cookieAction}/>
    </div>
  </div>
);

export default OptOut