import React from "react"
import styles from "./pageSection.module.scss"

const PageSection = ({title, description, descriptionImg, altDescImg, maxColumns, maxWidth, bgColor, children}) => (
  <section className={styles["page-section"]} style={{backgroundColor: bgColor || ""}}>
      {title &&
      <div className={styles["page-section_info"]}>
          <h2>{title}</h2>
          {description && <p>{descriptionImg && altDescImg && <img src={descriptionImg} alt={altDescImg} width="200px"/>}{description}</p>}
      </div>
      }
      <div className={styles[`page-section_grid${maxColumns || ''}`]} style={{maxWidth}}>
          {children}
      </div>
  </section>
);

export default PageSection