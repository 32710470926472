import React from "react"
import Button from "../button/button"
import styles from "./mainSection.module.scss"

const MainSection = ({title, description, textColor, position, image, actionLabel, action, amplitude, amplitudeClickEvent, children}) => {
  const setContent = content => ({__html: content});

  const onClickHandler = () => {
    if(amplitude && amplitudeClickEvent) {
      amplitude.getInstance().logEvent(amplitudeClickEvent)
    }

    action()
  };

  return (
    <section className={styles["main-section"]} style={{backgroundImage: `url(../../${image})`}}>
      <div className={[styles["main-section_wrapper"], styles[`wrapper-${position || 'left'}`]].join(' ')}>
        <div className={[styles["main-section_wrapper_content"], position ? styles[`main-section_wrapper_content_${position}`] : "", textColor ? styles[`main-section_wrapper_content_${textColor}`] : ""].join(" ")}>
          {children}
          <h1>{title}</h1>
          {description && <div dangerouslySetInnerHTML={setContent(description)}/>}
          {actionLabel && action && <Button text={actionLabel} onClick={onClickHandler}/>}
        </div>
      </div>
    </section>
  )
}; 

export default MainSection