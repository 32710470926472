import React, { useState } from "react"
import { isMobile } from "react-device-detect"
import {useSelector} from "react-redux"
import Button from "../button/button"
import Input from "../input/input"
import Link from "../link/link"
import styles from "./card.module.scss"

const Card = ({
                  title,
                  subtitle,
                  image,
                  imageWidth,
                  imageHeight,
                  imageBgColor,
                  description,
                  descriptionColor,
                  price,
                  date,
                  action,
                  actionLabel,
                  actionVariant,
                  amplitude,
                  amplitudeClickEvent,
                  amplitudeLinkEvent,
                  link,
                  linkLabel,
                  linkIcon,
                  extraContent,
                  marginTopContent,
                  bgColor,
                  reverseDirection,
                  textAlign,
                  horizontal,
                  padding,
                  shadow,
                  imageShadow,
                  hasBgImage,
                  imageLeft,
                  main,
                  actionInput
}) => {
    const appState = useSelector(state => state.appReducer);
    const setContent = content => ({__html: content});
    const card = horizontal ? "hCard" : "vCard";
    let setImageWidth = imageWidth;
    let setImageHeight = imageHeight;
    let setImageXPos = "auto";
    let getDate = [];
    let setDateTimeLabel;
    let setDateTime;
    const [formData, setFormData] = useState({
        inputCard: "",
    });

    if (imageWidth && parseFloat(imageWidth.slice(0, -2)) > 300 && appState.windowWidth < 400) {
        setImageWidth = "250px";
        setImageHeight = "250px";
    }

    if (hasBgImage) {
        setImageXPos = appState.windowWidth > 767 ? "50px" : setImageXPos
    } else if (imageLeft) {
        setImageXPos = appState.windowWidth > 767 ? "0px" : setImageXPos
    }

    if (date) {
        getDate = date.split("-");
        setDateTimeLabel = getDate.length > 1 ? `${getDate[2]}.${getDate[1]}.${getDate[0]}` : getDate[0];
        setDateTime = getDate.length > 1 ? date : new Date().getFullYear()
    }

    const onClickHandler = () => {
        if(amplitude && amplitudeClickEvent) {
            amplitude.getInstance().logEvent(amplitudeClickEvent)
        }

        action()
    };

    const onLinkHandler = () => {
        if(amplitude && amplitudeLinkEvent) {
            amplitude.getInstance().logEvent(amplitudeLinkEvent)
        }

        return true
    };

    const changeInputHandler = (e) => {
        const {name, value, checked, type} = e.target;
        setFormData({...formData, [name]: type === "checkbox" ? checked : value})
    };

    return (
        <div className={[styles[card], shadow ? styles["card-shadow"] : "", padding ? styles["card-padding"] : "", main ? styles["card-main"] : "", reverseDirection && horizontal ? styles[`${card}-direction_reverse`]: ""].join(" ")} style={{backgroundColor: bgColor || "transparent"}}>
            {image &&
            <a href={link} onClick={onLinkHandler}>
                <div className={[styles[`${card}-bg`], imageBgColor ? styles[`card-bg${imageBgColor}`] : "", imageShadow ? styles["card-bg_shadow"] : ""].join(" ")} style={{marginLeft: reverseDirection && horizontal && !isMobile ? "24px" : ""}}>
                    <div className={styles[`${card}-image`]} style={{backgroundImage: `url(../../${image})`, width: setImageWidth || "120px", height: setImageHeight || "120px", marginLeft: setImageXPos}}/>
                </div>
            </a>
            }
            <div className={styles[`${card}-content`]} style={{marginTop: marginTopContent && horizontal && appState.windowWidth > 400 ? marginTopContent : ""}}>
                <div className={[imageLeft && isMobile ? styles[`${card}-content_top_left`] : styles[`${card}-content_top`]].join(" ")}>
                    {date && <time dateTime={setDateTime}>{setDateTimeLabel}</time>}
                    {title && <h3>{title}</h3>}
                    {subtitle && <h4>{subtitle}</h4>}
                    {description && <div style={{color: descriptionColor, textAlign: textAlign || ""}} dangerouslySetInnerHTML={setContent(description)}/>}
                    {link && linkLabel &&
                    <div className={styles[`${card}-content_link`]}>
                        <Link link={link} linkLabel={linkLabel} linkIcon={linkIcon} onClick={onLinkHandler}/>
                    </div>
                    }
                    {actionInput && 
                         <div className={styles[`${card}-content_input`]}>
                            <Input type="text" name="inputCard" value={formData.inputCard} prompt={actionInput.label} onChange={changeInputHandler}/>
                        </div>
                    }
                    {actionLabel && action &&
                    <div className={styles[`${card}-content_options`]} style={{marginTop: !extraContent ? "auto" : ""}}>
                        {price && <h1>{price}</h1>}
                        <Button text={actionLabel} variant={actionVariant} onClick={onClickHandler}/>
                    </div>
                    }
                </div>
                {extraContent &&
                <div className={styles[`${card}-content_extra`]}>
                    <div dangerouslySetInnerHTML={setContent(extraContent)}/>
                    <div className={styles[`${card}-content_options`]}>
                        {price && <h1>{price}</h1>}
                        {actionLabel && action && <Button text={actionLabel} variant={actionVariant} onClick={onClickHandler}/>}
                    </div>
                </div>
                }
            </div>
        </div>
    )
};

export default Card