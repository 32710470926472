import React from "react"
import { Link } from "gatsby"
import Icon from "../icon/icon"
import styles from "./link.module.scss"

const LinkWrapper = ({link, linkLabel, linkIcon, onClick}) => {
    let urlType = link && (link.indexOf('http://') === 0 || link.indexOf('https://') === 0) ? 'absolute' : 'relative';

    return (
        <div className={styles["link"]}>
            {link && linkLabel && urlType === 'absolute' &&
            <a href={link} target="_blank" rel="noopener noreferrer" onClick={() => onClick()}>
                {linkIcon &&
                <div className={styles["link-icon"]}>
                    <Icon icon={`${linkIcon}.svg`} size="16px"/>
                </div>
                }
                {linkLabel}
            </a>}
            {link && linkLabel && urlType === 'relative' &&
            <Link to={link} onClick={() => onClick()}>
                {linkIcon &&
                <div className={styles["link-icon"]}>
                    <Icon icon={`${linkIcon}.svg`} size="16px"/>
                </div>
                }
                {linkLabel}
            </Link>}
        </div>
    )
};

export default LinkWrapper