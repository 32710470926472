import React from "react"
import Button from "../button/button"
import styles from "./secondarySection.module.scss"

const SecondarySection = ({children, title, shortDescription, description, position, image, actionLabel, action, amplitude, amplitudeClickEvent}) => {
  const setContent = content => ({__html: content});

  const onClickHandler = () => {
    if(amplitude && amplitudeClickEvent) {
      amplitude.getInstance().logEvent(amplitudeClickEvent)
    }

    action()
  };

  return (
    <section className={styles["secondary-section"]} style={{backgroundImage: `url(../../${image})`}}>
      <div className={[styles["secondary-section_wrapper"], styles[`wrapper-${position || 'left'}`]].join(' ')}>
        <div className={styles["secondary-section_wrapper_content"]}>
          <h2>{title}</h2>
          {shortDescription && <h4>{shortDescription}</h4>}
          {description && <div dangerouslySetInnerHTML={setContent(description)}/>}
          {actionLabel && action && <Button text={actionLabel} onClick={onClickHandler}/>}
        </div>
      </div>
      {children}
    </section>
  )
};

export default SecondarySection