import React, { useState } from "react"
import { Link } from "gatsby"
import Icon from "../icon/icon"
import styles from "./appBar.module.scss"

const items = [
  {
    id: 1,
    display: true,
    en: {
      url: '/en/',
      label: 'Business account'
    },
    es: {
      url: '/',
      label: 'Cuenta empresarial'
    }
  },
  {
    id: 2,
    display: true,
    en: {
      url: '/en/platform',
      label: 'Platform'
    },
    es: {
      url: '/plataforma',
      label: 'Plataforma'
    }
  },
  {
    id: 3,
    display: true,
    en: {
      url: '/en/identity',
      label: 'Identity'
    },
    es: {
      url: '/identidad',
      label: 'Identidad'
    }
  },
  {
    id: 4,
    display: true,
    en: {
      url: '/en/taxes-sat',
      label: 'Taxes'
    },
    es: {
      url: '/impuestos-sat',
      label: 'Impuestos'
    }
  },
  {
    id: 5,
    display: true,
    en: {
      url: '/en/financing',
      label: 'Financing'
    },
    es: {
      url: '/financiamiento',
      label: 'Financiamiento'
    }
  },
  {
    id: 6,
    display: true,
    en: {
      url: '/en/about-us',
      label: 'About us'
    },
    es: {
      url: '/nosotros',
      label: 'Nosotros'
    }
  },
  {
    id: 7,
    display: false,
    en: {
      url: '/en/education',
      label: 'Education'
    },
    es: {
      url: '/educacion',
      label: 'Educación'
    }
  },
  {
    id: 8,
    display: false,
    en: {
      url: '/en/marketplace',
      label: 'Marketplace'
    },
    es: {
      url: '/marketplace',
      label: 'Marketplace'
    }
  },
  {
    id: 9,
    display: false,
    en: {
      url: '/en/pricing',
      label: 'Pricing'
    },
    es: {
      url: '/planes',
      label: 'Planes'
    }
  },
  {
    id: 10,
    display: false,
    en: {
      url: '/en',
      label: 'Bank account'
    },
    es: {
      url: '/',
      label: 'Cuenta bancaria'
    }
  },
  {
    id: 11,
    display: false,
    en: {
      url: '/en/wallet',
      label: 'Wallet'
    },
    es: {
      url: '/wallet',
      label: 'Wallet'
    }
  },
  {
    id: 12,
    display: false,
    en: {
      url: '/en/financial-revolution',
      label: 'Finantial revolution'
    },
    es: {
      url: '/revolucion-financiera',
      label: 'Revolución financiera'
    }
  },
  {
    id: 13,
    display: false,
    en: {
      url: '/en/connect',
      label: 'Connect'
    },
    es: {
      url: '/conectar',
      label: 'Connect'
    }
  },
];

const AppBar = ({pathname, isHome = false}) => {
  const [displayMenu, setDisplayMenu] = useState(false);
  let urlAssembled;
  let urlStrings;
  let lang;
  let itemsFiltered = items.filter(item => item.display);

  if (pathname === "/") {
    lang = "es";
    urlAssembled = pathname;
  } else {
    urlStrings = pathname.split("/").filter(item => item !== "");
    lang = urlStrings[0] === "en" ? "en" : "es";
    urlAssembled = urlStrings.map(item => `/${item}`).join("");
  }

  const currentItem = items.find(item => item[lang].url === urlAssembled);

  if (isHome) itemsFiltered = itemsFiltered.filter(item => item.id !== 1);

  return (
    <nav className={styles["app-bar"]}>
      <Link to={lang === "es" ? "/" : "/en"}>
        <div className={styles["app-bar_logo"]}/>
      </Link>
      <div tabIndex="0" role="button" aria-label="menuBtn" className={styles["app-bar_menuBtn"]} onClick={() => setDisplayMenu(!displayMenu)} onKeyUp={(e) => e.keyCode === 13 ? setDisplayMenu(!displayMenu) : e.preventDefault()}/>
      <div className={[styles["app-bar_menu"], styles[`app-bar_menu_${displayMenu ? 'open' : 'close'}`]].join(' ')}>
        <ul>
          {itemsFiltered.map(item => (
            <li key={item.id}>
              <Link to={item[lang].url}>{item[lang].label}</Link>
            </li>
          ))}
          {currentItem &&
          <li>
            <Icon icon="icon-globe.svg" size="20px"/><Link to={currentItem.en.url} className={styles["app-bar_menu_lang"]}>EN</Link><Link to={currentItem.es.url} className={styles["app-bar_menu_lang"]}>ES</Link>
          </li>
          }
        </ul>
      </div>
    </nav>
  )
};

export default AppBar
