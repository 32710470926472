import React from "react"
import { isMobile } from "react-device-detect";
import Icon from "../icon/icon"
import styles from "./footer.module.scss"

const Footer = ({content, altDescription, privacyPolicyAction, termsUseHandlerAction}) => {
  const setContent = content => ({ __html: content });

  return (
    <footer className={styles["footer"]}>
      <div className={styles["footer-disclaimer"]}>
        <div className={styles["footer-disclaimer_rights"]}>{content.rights}</div>
        <div className={styles["footer-disclaimer_content"]}>
          <div className={styles["footer-disclaimer_content_info"]}>
            <div dangerouslySetInnerHTML={setContent(altDescription || content.description)}/>
          </div>
          <div className={styles["footer-disclaimer_crafted"]}>{content.crafted}</div>
          <div className={styles["footer-disclaimer_content_social"]}>
            <a href="https://linkedin.com/company/evvafinanzas" target="_blank" rel="noopener noreferrer">
              <Icon icon="linkedin-icon.png" size="40px" />
            </a>
            <a href="https://twitter.com/evvafinanzas" target="_blank" rel="noopener noreferrer">
              <Icon icon="twitter-icon.png" size="40px"/>
            </a>
            <a href="https://facebook.com/evvafinanzas" target="_blank" rel="noopener noreferrer">
              <Icon icon="facebook-icon.png" size="40px" />
            </a>
          </div>
        </div>
        <div className={styles["footer-disclaimer_terms_privacy"]}>
          {isMobile ? (
            <div>
              <a href="../TC.pdf" target="_blank" rel="noopener noreferrer">{content.terms}</a>
              <a href="../PC.pdf" target="_blank" rel="noopener noreferrer">{content.privacy}</a>
            </div>
          ) : (
            <div>
              <span tabIndex={0} role="button"
                    onClick={() => termsUseHandlerAction()}
                    onKeyUp={(e) => e.keyCode === 13 ? termsUseHandlerAction() : e.preventDefault()}>{content.terms}</span>
              <span tabIndex={0} role="button"
                    onClick={() => privacyPolicyAction()}
                    onKeyUp={(e) => e.keyCode === 13 ? privacyPolicyAction() : e.preventDefault()}>{content.privacy}</span>
            </div>
          )}
        </div>
      </div>
    </footer>
  )
};

export default Footer